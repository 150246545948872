<template>
  <list-container-widget
    id="services"
    :title="widgetName"
    :app-id="78"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target">TARGET!!! ServicesListWidget - {{ targetIsVisible }}</div> -->
      <!-- Panel actions -->
      <ViewAllButton item-type="services" />
    </template>
    <b-card-body class="x-scroll-container">
      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items && items.length > 0">
        <div ref="container" class="x-scroll-container w-100" @scroll="onScroll">
          <!-- Items Loop -->
          <div
            v-for="item in items"
            :key="item.key"
            class="x-scroll-element mx-1 mb-3 p-0"
          >
            <service-card
              v-if="item.isPublished || (!item.isPublished && isStaff)"
              :item="item"
            />
          </div>
          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <b-row v-else-if="!canList" class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="servicesPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("no-permisions.message") }}
          </p>
        </b-col>
      </b-row>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="servicesPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p>
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>
    <description-addon v-model="isAppInfoModalVisible" :app-id="3" />
  </list-container-widget>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import DescriptionAddon from "@/@core/widgets/DescriptionAddon.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import ServicesPlaceholder from "@/assets/images/placeholders/light/projects.svg";
import ServicePlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { checkPermissions } from "@/@core/utils/roles-utils";
// import MembershipEventCreateModal from "@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import ServiceCard from "@/views/apps/services/components/ServiceCard.vue";

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: "ServicesListWidget",

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },
  
  components: {
    DescriptionAddon,
    ListContainerWidget,
    LanguageSelectorHeader,
    ServiceCard,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      serviceInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
      isAppInfoModalVisible: false,
      searchInput: "",
      results: [],
      selectedLanguage: this.currentLocale,
    };
  },

  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.communitiesOthers.services;
    },
    items() {
      if (this.itemsData) {
        const itemsToIterate = this.searchInput
          ? this.results
          : this.itemsData?.unpaginated;
        return itemsToIterate;
      }
      return [];
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    servicePlaceholder() {
      return ServicePlaceholder;
    },
    servicesPlaceholder() {
      return ServicesPlaceholder;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "service",
        this.loggedMemberRoles,
        this.currentCollective
      );
    },
    canList() {
      return checkPermissions(
        "index",
        "service",
        this.loggedMemberRoles,
        this.currentCollective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "service",
        this.loggedMemberRoles,
        this.currentCollective
      );
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("++++++++++++ --> WATCH!!! ServicesListWidget.vue (targetIsVisible) : ", newValue)
      if (newValue && !this.items.length) {
        // console.log("2 --> WATCH!!! ServicesListWidget.vue (targetIsVisible): ", newValue)
        this.loadAllData()
      }
    },
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  // async created() {
  //   this.isLoading = true;
  //   await this.fetchData();
  //   await this.fetchClassifiers();
  //   this.isLoading = false;
  // },

  methods: {
    async loadAllData () {
      this.isLoading = true;
      await this.fetchData();
      this.isLoading = false;
    },
    canFilter() {
      if (this.classifiers.length > 0) {
        this.showfilter = true;
      } else {
        this.showfilter = false;
      }
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    closeModal() {
      this.$bvModal.hide("modal-create-services");
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: "services",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          communityParentSlug: this.$store.getters.currentCollective.slug,
          count: 16,
          isService: true,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
  },
};
</script>
<style scoped>
.item-img {
  height: 200px !important;
}
.titulo {
  font-size: 1.2em;
}
.card-standard {
  height: 380px !important;
}
.precio {
  padding-top: 12px;
  margin-bottom: 5px !important;
  font-size: 1.5em;
  font-weight: 900 !important;
}
.card-text {
  margin-bottom: 2px !important;
}
</style>
