<template>
  <b-card no-body class="float-left mx-1 mb-3 p-0 card-standard">
    <b-link
      :to="
        canView
          ? {
              name: 'service',
              params: {
                slug: item.slug,
                communityId: $route.params.communityId,
              },
            }
          : null
      "
    >
      <!-- Image -->
      <b-card-body class="pb-2">
        <div class="item-img text-center">
          <b-img
            :alt="`${translate(item.name)}`"
            fluid
            :src="
              getImageSrc(item.logoURL) ||
              getImageSrc(item.bgURL) ||
              servicePlaceholder
            "
            class="card-image-medium"
          />
        </div>
        <!-- Service Details -->
        <b-card-text
          v-if="
            item.spaceSettings.minPrice != null ||
            item.spaceSettings.maxPrice != null
          "
        >
          <h5 class="font-weight-bold precio">
            <span
              v-if="item.spaceSettings.minPrice !== item.spaceSettings.maxPrice"
              >{{ $t("services.from") }}</span
            >
            {{ item.spaceSettings.minPrice.toLocaleString(currentLocale) }}
            {{ item.spaceSettings.minPriceCurrency.ISO }}
          </h5>
        </b-card-text>
        <div v-else class="min-height-2 mt-1" />

        <h4 class="mt-1 text-ellipsis-lines-2 titulo">
          {{ translate(item.name) }}
        </h4>
        <b-card-text class="html-text-ellipsis-4 text-secondary">
          {{ translate(item.headline) }}
        </b-card-text>
      </b-card-body>
      <!-- Service Actions -->
      <!--   <div v-if="false" class="text-center w-100 mt-1">
                <b-button
                  v-if="hasMeetingsSlotsAddOn || hasMeetingAddOn"
                  variant="light"
                  tag="a"
                  class="btn-wishlist w-50 rounded-0 px-1"
                  @click="handleCalendarMeetingOpen()"
                >
                  <feather-icon icon="CalendarIcon" class="mr-25" />
                  Crear meeting
                </b-button>
                <b-button
                  v-if="hasChatIndividualAddOn"
                  variant="primary"
                  tag="a"
                  class="btn-cart w-50 rounded-0 px-1"
                  @click="handleSidebarChange()"
                >
                  <feather-icon icon="MessageSquareIcon" class="mr-25" />
                  Chatear
                </b-button>
              </div> -->
    </b-link>
  </b-card>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { getImageResource } from "@/@core/utils/image-utils";
import { checkPermissions } from "@/@core/utils/roles-utils";
import ServicePlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";

export default {
  name: "ServiceCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    community: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canView() {
      return checkPermissions(
        "index",
        "service",
        this.loggedMemberRoles,
        this.community || this.collective
      );
    },
    servicePlaceholder() {
      return ServicePlaceholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
  },
};
</script>

<style></style>
