var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"float-left mx-1 mb-3 p-0 card-standard",attrs:{"no-body":""}},[_c('b-link',{attrs:{"to":_vm.canView
        ? {
            name: 'service',
            params: {
              slug: _vm.item.slug,
              communityId: _vm.$route.params.communityId,
            },
          }
        : null}},[_c('b-card-body',{staticClass:"pb-2"},[_c('div',{staticClass:"item-img text-center"},[_c('b-img',{staticClass:"card-image-medium",attrs:{"alt":("" + (_vm.translate(_vm.item.name))),"fluid":"","src":_vm.getImageSrc(_vm.item.logoURL) ||
            _vm.getImageSrc(_vm.item.bgURL) ||
            _vm.servicePlaceholder}})],1),(
          _vm.item.spaceSettings.minPrice != null ||
          _vm.item.spaceSettings.maxPrice != null
        )?_c('b-card-text',[_c('h5',{staticClass:"font-weight-bold precio"},[(_vm.item.spaceSettings.minPrice !== _vm.item.spaceSettings.maxPrice)?_c('span',[_vm._v(_vm._s(_vm.$t("services.from")))]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.spaceSettings.minPrice.toLocaleString(_vm.currentLocale))+" "+_vm._s(_vm.item.spaceSettings.minPriceCurrency.ISO)+" ")])]):_c('div',{staticClass:"min-height-2 mt-1"}),_c('h4',{staticClass:"mt-1 text-ellipsis-lines-2 titulo"},[_vm._v(" "+_vm._s(_vm.translate(_vm.item.name))+" ")]),_c('b-card-text',{staticClass:"html-text-ellipsis-4 text-secondary"},[_vm._v(" "+_vm._s(_vm.translate(_vm.item.headline))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }